export const fetchTokensUsingOpenSea = async (ownerAddress: string, offset: number, limit: number) => {
  let url = `https://api.opensea.io/api/v1/assets`
  url += `?asset_contract_address=0x50f5474724e0ee42d9a4e711ccfb275809fd6d4a`
  // url += `&owner=0x3f86f18322a888d9b3adef38f127c941bccc014d`
  url += `&owner=${ownerAddress}`
  url += `&offset=${offset}&limit=${limit}`
  url += `&order_direction=asc`

  const options = {
    method: 'GET',
    headers: {
      'X-API-KEY': 'b0d42b956c7f4cc7b7eb9e60686a8440'
    }
  }

  try {
    const res = await fetch(url, options)
    const json = await res.json()

    if (json.length === 0) {
      return {
        allTokensFetched: true,
        tokens: []
      }
    }

    const output: any = {
      allTokensFetched: false,
      tokens: []
    }

    if (json.assets.length < limit) {
      output.allTokensFetched = true
    }

    json.assets.forEach((token: any) => {
      output.tokens[token.token_id] = {
        isSelected: false,
        imageUri: token.image_original_url,
        name: token.name
      }
    })

    return output
  } catch (error) {
    console.error(error)
    return error
  }
}

export const fetchTokensUsingAxie = async (ownerAddress: string, offset: number, selectedCurrencySymbol: string) => {
  let url = `https://axieinfinity.com/api/v2/addresses/${ownerAddress}/axies`
  url += `?sorting=lowest_id`
  url += `&offset=${offset}`
  if (selectedCurrencySymbol === 'WOA') {
    url += `&class=beast&class=aquatic&class=plant&title=origin&num_mystic=0`
  }

  try {
    const res = await fetch(url)
    const { axies } = await res.json()
    if (axies.length === 0) {
      return {
        allTokensFetched: true,
        tokens: []
      }
    }

    const output: any = {
      allTokensFetched: false,
      tokens: []
    }

    if (axies.length < 12) {
      output.allTokensFetched = true
    }

    if (selectedCurrencySymbol === 'WOA') {
      axies.forEach((token: any) => {
        if (token.breedCount <= 2) {
          output.tokens[token.id] = {
            isSelected: false,
            name: token.name,
            imageUri: token.image
          }
        }
      })
    } else {
      axies.forEach((token: any) => {
        output.tokens[token.id] = {
          isSelected: false,
          name: token.name,
          imageUri: token.image
        }
      })
    }

    return output
  } catch (error) {
    console.error(error)
    return error
  }
}

export const fetchTokensUsingCryptoKittes = async (
  ownerAddress: string,
  offset: number,
  selectedCurrencySymbol: string
) => {
  let url = `https://public.api.cryptokitties.co/v1/kitties`
  url += `?owner_wallet_address=${ownerAddress}`
  url += `&orderBy=kitties.id&orderDirection=asc`
  url += `&offset=${offset}`

  if (selectedCurrencySymbol === 'WVG0') {
    url += '&gen=0&cooldown=0'
  } else if (selectedCurrencySymbol === 'WG0') {
    url += '&gen=0'
  }

  const options = {
    method: 'GET',
    headers: {
      'x-api-token': 'vzNIa96lWRTxIsoFK0sFzIqrGMJkm2s8LUS-GaGijms'
    }
  }

  try {
    const res = await fetch(url, options)
    const { kitties } = await res.json()
    if (kitties.length === 0) {
      return {
        allTokensFetched: true,
        tokens: []
      }
    }

    const output: any = {
      allTokensFetched: false,
      tokens: []
    }

    if (kitties.length < 12) {
      output.allTokensFetched = true
    }

    kitties.forEach((token: any) => {
      output.tokens[token.id] = {
        isSelected: false,
        name: token.name || 'Cryptokitty',
        imageUri: token.image_url
      }
    })

    return output
  } catch (error) {
    console.error(error)
    return error
  }
}
