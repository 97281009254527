import { Currency, currencyEquals, ETHER, WETH } from '@uniswap/sdk'
import { useMemo } from 'react'
import { tryParseAmount } from '../state/swap/hooks'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useCurrencyBalance } from '../state/wallet/hooks'
import { useActiveWeb3React } from './index'
import { calculateGasMargin } from '../utils'
import { useWETHContract, useERC721WrapContract } from './useContract'

export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE }
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined,
  typedValue: string | undefined
): { wrapType: WrapType; execute?: undefined | (() => Promise<void> | string); inputError?: string } {
  const { chainId, account } = useActiveWeb3React()
  const wethContract = useWETHContract()
  const balance = useCurrencyBalance(account ?? undefined, inputCurrency)
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(() => tryParseAmount(typedValue, inputCurrency), [inputCurrency, typedValue])
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return NOT_APPLICABLE

    const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount)

    if (inputCurrency === ETHER && currencyEquals(WETH[chainId], outputCurrency)) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  const txReceipt = await wethContract.deposit({ value: `0x${inputAmount.raw.toString(16)}` })
                  addTransaction(txReceipt, { summary: `Wrap ${inputAmount.toSignificant(6)} ETH to WETH` })
                } catch (error) {
                  console.error('Could not deposit', error)
                  throw error
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : 'Insufficient ETH balance'
      }
    } else if (currencyEquals(WETH[chainId], inputCurrency) && outputCurrency === ETHER) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  const txReceipt = await wethContract.withdraw(`0x${inputAmount.raw.toString(16)}`)
                  addTransaction(txReceipt, { summary: `Unwrap ${inputAmount.toSignificant(6)} WETH to ETH` })
                } catch (error) {
                  console.error('Could not withdraw', error)
                  throw error
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : 'Insufficient WETH balance'
      }
    } else {
      return NOT_APPLICABLE
    }
  }, [wethContract, chainId, inputCurrency, outputCurrency, inputAmount, balance, addTransaction])
}

/**
 * Given the selected token and ids to wrap, return a wrap callback
 * @param Token the selected token
 * @param IDsToWrap the ids of the nft's to wrap
 */
export function useNFTWrapCallback(
  tokenAddress?: string,
  IDsToWrap?: number[]
): { execute?: undefined | (() => Promise<void | string>) } {
  //const { account } = useActiveWeb3React()
  const erc721WrapContract = useERC721WrapContract(tokenAddress)
  const addTransaction = useTransactionAdder()
  return useMemo(() => {
    if (!erc721WrapContract || !IDsToWrap || IDsToWrap.length < 1)
      return {
        execute: undefined
      }
    return {
      execute: async () => {
        try {
          const txReceipt = await erc721WrapContract.wrap(IDsToWrap)
          addTransaction(txReceipt, { summary: `Wrap NFTS into tokens` })
          return txReceipt
        } catch (error) {
          console.error('Could not deposit', error)
          throw error
        }
      }
    }
  }, [erc721WrapContract, addTransaction, IDsToWrap])
}

/**

/**
 * Given the selected token and amount to unwrap, return a wrap callback
 * @param Token the selected token
 * @param IDsToWrap the ids of the nft's to wrap
 */
export function useNFTUnWrapCallback(
  tokenAddress?: string,
  amountToUnwrap?: string
): { execute?: undefined | (() => Promise<void | string>) } {
  const erc721WrapContract = useERC721WrapContract(tokenAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    const amountToUnwrapInt = amountToUnwrap ? parseInt(amountToUnwrap) : 0
    if (!erc721WrapContract || amountToUnwrapInt === 0)
      return {
        execute: undefined
      }

    return {
      execute: async () => {
        try {
          const estimatedGas = await erc721WrapContract.estimateGas.unwrap(amountToUnwrapInt)
          const txReceipt = await erc721WrapContract.unwrap(amountToUnwrapInt, {
            gasLimit: calculateGasMargin(estimatedGas)
          })
          addTransaction(txReceipt, { summary: `Unwrap ` + amountToUnwrapInt + ` tokens` })
          return txReceipt
        } catch (error) {
          console.error('Could not deposit', error)
          throw error
        }
      }
    }
  }, [erc721WrapContract, addTransaction, amountToUnwrap])
}
