import { Token, ChainId } from '@uniswap/sdk'

interface Props {
  [chain: string]: {
    [tokenName: string]: {
      token: Token
    }
  }
}

export const erc20ToErc721TokenInfo: Props = {
  [ChainId.MAINNET]: {
    WOA: {
      token: new Token(ChainId.MAINNET, '0xf5b0a3efb8e8e4c201e2a935f110eaaf3ffecb8d', 0, 'AXIE', 'Axie Infinity')
    },
    WVG0: {
      token: new Token(ChainId.MAINNET, '0x06012c8cf97BEaD5deAe237070F9587f8E7A266d', 0, 'CK', 'CryptoKitties')
    },
    WG0: {
      token: new Token(ChainId.MAINNET, '0x06012c8cf97BEaD5deAe237070F9587f8E7A266d', 0, 'CK', 'CryptoKitties')
    },
    WCK: {
      token: new Token(ChainId.MAINNET, '0x06012c8cf97BEaD5deAe237070F9587f8E7A266d', 0, 'CK', 'CryptoKitties')
    },
    WSL: {
      token: new Token(ChainId.MAINNET, '0x50f5474724e0ee42d9a4e711ccfb275809fd6d4a', 0, 'S-LAND', 'The Sandbox')
    }
  },
  [ChainId.ROPSTEN]: {
    TWOA: {
      token: new Token(ChainId.ROPSTEN, '0x9579c67818Ee9F8F4A26f039C32522bb375A9849', 0, 'AXIE', 'Axie Infinity')
    }
  },
  [ChainId.KOVAN]: {
    TWOA: {
      token: new Token(ChainId.KOVAN, '0xAC70c81405314455908451F429b5c4511b3dfd1A', 0, 'AXIE', 'Axie Infinity')
    }
  }
}
