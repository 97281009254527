import React from 'react'
import { RowBetween } from 'components/Row'
import { CloseIcon } from 'theme'
import { Text } from 'rebass'

export default function SendHeader({ onDismiss }: { onDismiss: () => void }) {
  return (
    <RowBetween>
      <Text fontWeight={500} fontSize={16}>
        Gift
      </Text>
      <CloseIcon onClick={onDismiss} />
    </RowBetween>
  )
}
