import React from 'react'
import styled from 'styled-components'

const CardStyle = styled.div`
  width: 100%;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 7px;
  ${({ theme }) =>
    theme.mediaWidth.upToMedium({
      width: '100%',
      paddingBottom: '24px!important',
      paddingRight: '24px!important',
      flex: '0 0 33.3333333%',
      maxWidth: '33.3333333%'
    })}
  ${({ theme }) =>
    theme.mediaWidth.upToSmall({
      width: '100%',
      paddingBottom: '12px!important',
      paddingRight: '12px!important',
      paddingLeft: '0!important',
      flex: '0 0 50%',
      maxWidth: '50%'
    })};
`

const WrapperContent = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 120px;
  position: relative;
  border-radius: 7px;

  overflow: hidden;
  padding-bottom: 4px;
`
// border: 1px solid ${({ theme }) => theme.bg2}

const TokenCardImage = styled.img`
  width: 100%;
  max-height: 320px;
`
const TokenCardInfo = styled.div`
  padding-left: 21px;
  padding-top: 6px;
  ${({ theme }) =>
    theme.mediaWidth.upToMedium({
      paddingLeft: '30px'
    })}
`

const TokenCardCurrencyName = styled.div`
  width: 100%;
  font-size: 14px;

  border-style: none;
  color: ${({ theme }) => theme.text1};
`

const TokenCardId = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
`

interface TokenCardProps {
  tokenId: number | string
  imageUri: string
  name: string
}

const TokenCard = ({ tokenId, imageUri, name }: TokenCardProps) => {
  return (
    <CardStyle>
      <WrapperContent>
        <TokenCardImage src={imageUri} />
        <TokenCardInfo>
          <TokenCardCurrencyName>{name}</TokenCardCurrencyName>
          <TokenCardId>#{tokenId}</TokenCardId>
        </TokenCardInfo>
      </WrapperContent>
    </CardStyle>
  )
}
export default TokenCard
