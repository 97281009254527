import React from 'react'
import { ButtonConfirmed } from '../../../components/Button'
import { AutoRow } from '../../../components/Row'
import Loader from '../../../components/Loader'
import { ApprovalState } from '../../../hooks/useApproveCallback'

interface Props {
  approveCallback: () => Promise<void>
  approval: ApprovalState
  approvalSubmitted: boolean
  selectedCurrencySymbol: string
}

const ApprovalButton = ({ approveCallback, approval, approvalSubmitted, selectedCurrencySymbol }: Props) => {
  return (
    <ButtonConfirmed
      onClick={approveCallback}
      disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
      width="48%"
      // altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
      confirmed={approval === ApprovalState.APPROVED}
    >
      {approval === ApprovalState.PENDING ? (
        <AutoRow gap="6px" justify="center">
          Approving <Loader stroke="white" />
        </AutoRow>
      ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
        'Approved'
      ) : (
        'Approve ' + selectedCurrencySymbol
      )}
    </ButtonConfirmed>
  )
}

export default ApprovalButton
