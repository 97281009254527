import React, { useState } from 'react'
import { Token } from '@uniswap/sdk'
import { useTokenBalanceForERC721 } from 'state/wallet/hooks'
import { SelectableTokenHolder } from 'components/SearchModal/Wrap/types'
import SearchedAndSelectedTokens from 'components/SearchModal/Wrap/SearchedAndSelectedTokens/SearchedAndSelectedTokens'
import { TYPE } from 'theme'

export default function WalletCollectibles({ currency, account }: { currency: Token; account: string }) {
  const tokenBalance = parseInt(useTokenBalanceForERC721(currency, account ?? ''))

  const [displayedTokens, setDisplayedTokens] = useState<SelectableTokenHolder>({})
  const [searchQuery /* setSearchQuery*/] = useState<string>('')

  return (
    <>
      <div
        style={{
          marginLeft: '12px',
          marginTop: '8px'
        }}
      >
        <TYPE.black fontWeight={600} fontSize="22px">
          {currency.name}
        </TYPE.black>
        {!Number.isNaN(tokenBalance) && tokenBalance > 0 && (
          <TYPE.black fontWeight={400} fontSize="18px" marginTop="5px">
            {tokenBalance} {currency.name}
          </TYPE.black>
        )}

        <br />
      </div>
      {currency.symbol && (
        <SearchedAndSelectedTokens
          searchQuery={searchQuery}
          tokenBalance={tokenBalance}
          account={account}
          displayedTokens={displayedTokens}
          setDisplayedTokens={setDisplayedTokens}
          token={currency}
          selectedCurrencySymbol={currency.symbol}
          approvalProcessComplete={false}
          isWalletView={true}
        />
      )}
    </>
  )
}
