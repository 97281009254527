import { Currency, Token } from '@uniswap/sdk'
import React, { useState, useEffect, useCallback, RefObject, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentWrapper, TokenSearchGridLayout } from './wrapStyleds'
import { SelectableTokenHolder } from './types'
import { useActiveWeb3React } from 'hooks'
import useToggle from 'hooks/useToggle'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useNFTWrapCallback, useNFTUnWrapCallback } from 'hooks/useWrapCallback'
import { ApprovalState, useApproveForAllCallback } from 'hooks/useApproveCallback'
import { useTokenBalanceForERC721 } from 'state/wallet/hooks'
import { useRecentTransactions } from 'state/transactions/hooks'
import { erc20ToErc721TokenInfo } from 'constants/erc20ToErc721Token/erc20ToErc721TokenInfo'
import { SearchInput } from '../styleds'
import { WrapHeader } from './WrapHeader'
import Column from '../../../components/Column'
import { RowBetween } from '../../../components/Row'
import { BottomGrouping } from '../../../components/swap/styleds'
import ProgressSteps from '../../../components/ProgressSteps'
import { WrapUnwrapButton } from './WrapUnwrapButton'
import TransactionConfirmationModal, { TransactionErrorContent } from '../../TransactionConfirmationModal'
import SearchedAndSelectedTokens from './SearchedAndSelectedTokens/SearchedAndSelectedTokens'
import ApprovalButton from './ApprovalButton'
import { updateListOfSelectedTokenIds } from './updateListOfSelectedTokenIds'
import { pendingTransactionsHaveFinished } from './pendingTransactionsHaveFinished'

interface WrapProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect?: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showManageView?: () => void
  showImportView?: () => void
}

export function Wrap({ selectedCurrency, onDismiss, isOpen }: WrapProps) {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const [showWrapMode, setShowWrapMode] = useState(true)
  const selectedCurrencySymbol = selectedCurrency && selectedCurrency.symbol != null ? selectedCurrency.symbol : ''
  const currentChainId = chainId ? chainId : ''
  const erc20ToErc721TokenInfoBySelectedCurrencySymbol = erc20ToErc721TokenInfo[currentChainId][selectedCurrencySymbol]
  const token =
    erc20ToErc721TokenInfoBySelectedCurrencySymbol && erc20ToErc721TokenInfoBySelectedCurrencySymbol.token
      ? erc20ToErc721TokenInfoBySelectedCurrencySymbol.token
      : undefined
  const tokenBalance = parseInt(useTokenBalanceForERC721(token, account ?? ''))
  const [displayedTokens, setDisplayedTokens] = useState<SelectableTokenHolder>({})
  const [searchQuery, setSearchQuery] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>()
  const recentTransactions = useRecentTransactions()
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false)
  const [transactionErrorMessage, setTransactionErrorMessage] = useState<string>('')
  const [awaitingUserConfirmationInWallet, setAwaitingUserConfirmationInWallet] = useState<boolean>(false)
  const [pendingStateFromMostRecentRender, setPendingStateFromMostRecentRender] = useState<boolean>(false)
  const [approvalStateFromMostRecentRender, setApprovalStateFromMostRecentRender] = useState<ApprovalState>()
  const [approvalProcessComplete, setApprovalProcessComplete] = useState<boolean>(false)
  const [selectedTokenIds, setSelectedTokenIds] = useState<number[]>([])

  useEffect(() => {
    if (recentTransactions.pending.length > 0) setAwaitingUserConfirmationInWallet(false)
  }, [recentTransactions.pending])

  const selectedTokenAddress = selectedCurrency instanceof Token ? selectedCurrency.address : undefined
  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveForAllCallback(token, selectedTokenAddress)
  const numberOfSelectedTokens = Object.keys(displayedTokens).reduce(
    (acc: number, token: string) => (displayedTokens[token].isSelected ? acc + 1 : acc),
    0
  )

  useEffect(() => {
    const newPendingState = recentTransactions.pending.length > 0
    if (pendingTransactionsHaveFinished(newPendingState, pendingStateFromMostRecentRender) && approvalProcessComplete)
      onDismiss()
    setPendingStateFromMostRecentRender(newPendingState)
  }, [recentTransactions.pending.length, pendingStateFromMostRecentRender, onDismiss, approvalProcessComplete])

  useEffect(() => {
    if (approvalStateFromMostRecentRender !== approval) setApprovalStateFromMostRecentRender(approval)
    else if (approvalStateFromMostRecentRender === ApprovalState.APPROVED) setApprovalProcessComplete(true)
  }, [approval, approvalStateFromMostRecentRender])

  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen])

  const handleInput = useCallback(event => {
    setSearchQuery(event.target.value)
  }, [])

  useEffect(() => {
    updateListOfSelectedTokenIds(displayedTokens, setSelectedTokenIds)
  }, [displayedTokens])
  const { execute: nftWrapCallback } = useNFTWrapCallback(selectedTokenAddress, selectedTokenIds)
  const { execute: nftUnwrapCallback } = useNFTUnWrapCallback(selectedTokenAddress, searchQuery)
  const handleWrapOrUnwrapNFT = async (wrapOrUnwrapCallback: any) => {
    if (!wrapOrUnwrapCallback) return
    setConfirmationModalOpen(true)
    setAwaitingUserConfirmationInWallet(true)
    setTransactionErrorMessage('')
    setSearchQuery('')
    try {
      await wrapOrUnwrapCallback()
    } catch (error) {
      console.log(error)
      setTransactionErrorMessage(error?.error?.message ? error.error.message : 'transaction error')
      setAwaitingUserConfirmationInWallet(false)
    }
  }

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !showWrapMode) handleWrapOrUnwrapNFT(nftUnwrapCallback)
  }

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const showApproveFlow =
    approval === ApprovalState.NOT_APPROVED ||
    approval === ApprovalState.PENDING ||
    (approvalSubmitted && approval === ApprovalState.APPROVED)

  const [open, toggle] = useToggle(false)
  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, open ? toggle : undefined)

  // if no results on main list, show option to expand into inactiv
  const handleDismissTransactionConfirmationModal = () => setConfirmationModalOpen(false)

  return (
    <ContentWrapper>
      <TokenSearchGridLayout>
        <WrapHeader showWrapMode={showWrapMode} setShowWrapMode={setShowWrapMode} onDismiss={onDismiss} />
        <SearchInput
          type="text"
          id="token-search-input"
          placeholder={showWrapMode ? t('enterTokenID') : t('enterAmountUnwrap')}
          autoComplete="off"
          value={searchQuery}
          ref={inputRef as RefObject<HTMLInputElement>}
          onChange={handleInput}
          onKeyDown={handleEnter}
        />
        {showWrapMode && token && (
          <SearchedAndSelectedTokens
            searchQuery={searchQuery}
            tokenBalance={tokenBalance}
            account={account}
            displayedTokens={displayedTokens}
            setDisplayedTokens={setDisplayedTokens}
            token={token}
            selectedCurrencySymbol={selectedCurrencySymbol}
            approvalProcessComplete={approvalProcessComplete}
            isWalletView={false}
          />
        )}
        <TransactionConfirmationModal
          isOpen={confirmationModalOpen}
          onDismiss={handleDismissTransactionConfirmationModal}
          attemptingTxn={awaitingUserConfirmationInWallet}
          hash={recentTransactions.pending[0]}
          pendingText={`Confirm ${showWrapMode ? 'wrap' : 'unwrap'}`}
          content={
            transactionErrorMessage
              ? () => (
                  <TransactionErrorContent
                    message={transactionErrorMessage}
                    onDismiss={handleDismissTransactionConfirmationModal}
                  />
                )
              : undefined
          }
          currencyToAdd={selectedCurrency || undefined}
        />
        <BottomGrouping>
          {showWrapMode && showApproveFlow ? (
            <RowBetween>
              <ApprovalButton
                approveCallback={approveCallback}
                approval={approval}
                approvalSubmitted={approvalSubmitted}
                selectedCurrencySymbol={selectedCurrencySymbol}
              />
              <WrapUnwrapButton
                width={'48%'}
                showWrapMode={showWrapMode}
                onWrapClick={() => handleWrapOrUnwrapNFT(nftWrapCallback)}
                onUnwrapClick={() => handleWrapOrUnwrapNFT(nftUnwrapCallback)}
                pendingTransactions={recentTransactions.pending}
                approval={approval}
                ApprovalState={ApprovalState}
                numberOfSelectedTokens={numberOfSelectedTokens}
                searchQuery={searchQuery}
              />
            </RowBetween>
          ) : (
            <WrapUnwrapButton
              width={'100%'}
              showWrapMode={showWrapMode}
              onWrapClick={() => handleWrapOrUnwrapNFT(nftWrapCallback)}
              onUnwrapClick={() => handleWrapOrUnwrapNFT(nftUnwrapCallback)}
              pendingTransactions={recentTransactions.pending}
              approval={approval}
              ApprovalState={ApprovalState}
              numberOfSelectedTokens={numberOfSelectedTokens}
              searchQuery={searchQuery}
            />
          )}
          {showWrapMode && showApproveFlow && (
            <Column style={{ marginTop: '1rem' }}>
              <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
            </Column>
          )}
        </BottomGrouping>
      </TokenSearchGridLayout>
    </ContentWrapper>
  )
}
