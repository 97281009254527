import React, { useCallback, useState, useEffect } from 'react'
import { Currency, CurrencyAmount } from '@uniswap/sdk'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import useENS from 'hooks/useENS'
import { useWalletModalToggle } from 'state/application/hooks'
import { useRecentTransactions } from 'state/transactions/hooks'
import { useSendERC20Callback, useSendETHCallback } from 'hooks/useSendCallback'
import { useActiveWeb3React } from 'hooks'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { useCurrencyBalance } from 'state/wallet/hooks'

import { Wrapper, PaddedColumn, BottomGrouping } from './styleds'
import { Text } from 'rebass'
import Modal from 'components/Modal'
import SendHeader from 'components/SendModal/SendHeader'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from './CurrencyInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary } from 'components/Button'
import AddressInputPanel from 'components/AddressInputPanel'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'

interface SendModalProps {
  isOpen: boolean
  onDismiss: () => void
}

export default function SendModal({ isOpen, onDismiss }: SendModalProps) {
  const [inputAddressReceiver, setInputAddressReceiver] = useState<string>('')
  const [inputAmount, setInputAmount] = useState<string>('')
  const [selectedCurrency, setSelectedCurrency] = useState<undefined | Currency>(undefined)
  const { account } = useActiveWeb3React()
  const currencyBalance = useCurrencyBalance(account || '', selectedCurrency)

  const toggleWalletModal = useWalletModalToggle()
  const recentTransactions = useRecentTransactions()

  const { address, loading } = useENS(inputAddressReceiver)

  const { execute: sendERC20Callback } = useSendERC20Callback(selectedCurrency, inputAmount, inputAddressReceiver)
  const { execute: sendETHCallback, notReadyToExecute: isSendETHNotReady } = useSendETHCallback(
    inputAmount,
    inputAddressReceiver
  )

  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false)
  const [awaitingUserConfirmationInWallet, setAwaitingUserConfirmationInWallet] = useState<boolean>(false)
  const [transactionErrorMessage, setTransactionErrorMessage] = useState<string>('')

  const handleTypeInput = useCallback(
    (value: string) => {
      setInputAmount(value)
    },
    [setInputAmount]
  )

  useEffect(() => {
    if (recentTransactions.pending.length > 0) {
      setAwaitingUserConfirmationInWallet(false)
    }
  }, [recentTransactions.pending])

  const handleSendERC20Callback = async (sendERC20Callback: any) => {
    setConfirmationModalOpen(true)
    setAwaitingUserConfirmationInWallet(true)
    setTransactionErrorMessage('')
    try {
      if (selectedCurrency instanceof WrappedTokenInfo) {
        await sendERC20Callback()
      } else {
        sendETHCallback && (await sendETHCallback())
      }
    } catch (error) {
      console.log(error)
      setTransactionErrorMessage(error?.error?.message ? error.error.message : 'transaction error')
      setAwaitingUserConfirmationInWallet(false)
    }
  }

  const errorInputAddress = inputAddressReceiver.length > 0 && !address && !loading

  const handleInputSelect = useCallback(inputCurrency => {
    setSelectedCurrency(inputCurrency)
  }, [])

  const handleTypeOutput = useCallback(
    (value: string) => {
      setInputAddressReceiver(value)
    },
    [setInputAddressReceiver]
  )

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalance)

  const handleMaxInput = useCallback(() => {
    maxAmountInput && setInputAmount(maxAmountInput.toExact())
  }, [maxAmountInput, setInputAmount])

  const handleDismissTransactionConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false)
    setInputAddressReceiver('')
    setInputAmount('')
    setSelectedCurrency(undefined)
    onDismiss()
  }, [onDismiss])

  const messageError: string | null = ((): string | null => {
    if (loading && inputAddressReceiver.length > 0) {
      return '...'
    } else if (errorInputAddress) {
      return 'Invalid address'
    } else if (!selectedCurrency) {
      return 'Select a token'
    } else if (!inputAmount) {
      return 'Enter an amount'
    } else if (maxAmountInput && inputAmount > maxAmountInput.toExact()) {
      return 'Amount should be less than Or equal balance!'
    } else if (inputAddressReceiver.length === 0) {
      return 'Enter an address'
    } else {
      return null
    }
  })()

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={80}>
      <Wrapper>
        <PaddedColumn gap="16px">
          <SendHeader onDismiss={onDismiss} />
          <AutoColumn gap={'md'}>
            <CurrencyInputPanel
              value={inputAmount}
              id="send-currency-input"
              label="Amount"
              currency={selectedCurrency}
              onUserInput={handleTypeInput}
              onCurrencySelect={handleInputSelect}
              onMax={handleMaxInput}
            />
            <AddressInputPanel value={inputAddressReceiver} onChange={handleTypeOutput} />
          </AutoColumn>
          <BottomGrouping>
            {isSendETHNotReady ? null : !account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
            ) : !messageError ? (
              <ButtonPrimary onClick={() => handleSendERC20Callback(sendERC20Callback)}>Send</ButtonPrimary>
            ) : (
              <ButtonError id="send-button" error={false} disabled={true}>
                <Text fontSize={20} fontWeight={500}>
                  {messageError}
                </Text>
              </ButtonError>
            )}
          </BottomGrouping>
        </PaddedColumn>
        <TransactionConfirmationModal
          isOpen={confirmationModalOpen}
          onDismiss={handleDismissTransactionConfirmationModal}
          attemptingTxn={awaitingUserConfirmationInWallet}
          hash={recentTransactions.pending[0]}
          pendingText={`Confirm send`}
          content={
            transactionErrorMessage
              ? () => (
                  <TransactionErrorContent
                    message={transactionErrorMessage}
                    onDismiss={handleDismissTransactionConfirmationModal}
                  />
                )
              : undefined
          }
          currencyToAdd={selectedCurrency || undefined}
        />
      </Wrapper>
    </Modal>
  )
}
