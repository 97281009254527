import React from 'react'
import { Text } from 'rebass'
import { PaddedColumn } from '../styleds'
import { RowBetween } from '../../Row'
import { ToggleWrapper, ToggleOption } from './wrapStyleds'
import { CloseIcon } from '../../../theme'

interface WrapHeaderProps {
  showWrapMode: boolean
  setShowWrapMode: (wrapMode: boolean) => void
  onDismiss: () => void
}

export function WrapHeader({ showWrapMode, setShowWrapMode, onDismiss }: WrapHeaderProps) {
  return (
    <>
      <RowBetween>
        <Text fontWeight={500} fontSize={16}>
          Wrap NFTs
        </Text>
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <PaddedColumn style={{ padding: 0 }}>
        <ToggleWrapper>
          <ToggleOption onClick={() => setShowWrapMode(!showWrapMode)} active={showWrapMode}>
            Wrap
          </ToggleOption>
          <ToggleOption onClick={() => setShowWrapMode(!showWrapMode)} active={!showWrapMode}>
            Unwrap
          </ToggleOption>
        </ToggleWrapper>
      </PaddedColumn>
    </>
  )
}
