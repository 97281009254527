import React, { Dispatch, SetStateAction } from 'react'
import { Token } from '@uniswap/sdk'
import WRAPTraditionalAPI from './WRAPTraditionalAPI'
import WrapBase from '../WrapBase/WrapBase'
import { SelectableTokenHolder } from '../types'

interface Props {
  token: Token
  tokenBalance: number
  account: string | null | undefined
  displayedTokens: SelectableTokenHolder
  setDisplayedTokens: Dispatch<SetStateAction<SelectableTokenHolder>>
  selectedCurrencySymbol: string
  searchQuery: string
  approvalProcessComplete: boolean
  isWalletView: boolean
}

const SearchedAndSelectedTokens = ({
  token,
  tokenBalance,
  account,
  displayedTokens,
  setDisplayedTokens,
  selectedCurrencySymbol,
  searchQuery,
  approvalProcessComplete,
  isWalletView
}: Props) => {
  switch (selectedCurrencySymbol) {
    case 'WCK':
    case 'WG0':
    case 'WVG0':
    case 'WOA':
    case 'WSL':
    case 'CK':
    case 'AXIE':
    case 'S-LAND':
      return (
        <WRAPTraditionalAPI
          selectedCurrencySymbol={selectedCurrencySymbol}
          account={account}
          displayedTokens={displayedTokens}
          setDisplayedTokens={setDisplayedTokens}
          isWalletView={isWalletView}
        />
      )
    case 'TWOA':
    default:
      return (
        <WrapBase
          searchQuery={searchQuery}
          tokenBalance={tokenBalance}
          account={account}
          token={token}
          displayedTokens={displayedTokens}
          setDisplayedTokens={setDisplayedTokens}
          approvalProcessComplete={approvalProcessComplete}
          isWalletView={isWalletView}
        />
      )
  }
}

export default SearchedAndSelectedTokens
