import { Pair } from '../../data/Pair'
import React, { useState, useCallback } from 'react'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import NFTWrapModal from '../SearchModal/Wrap/NFTWrapModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { Currency } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import useTheme from '../../hooks/useTheme'
import { erc20ToErc721TokenInfo } from '../../constants/erc20ToErc721Token/erc20ToErc721TokenInfo'
import { getEtherscanLink } from '../../utils'
import { ExternalLink } from '../../theme/components'
import {
  InputRow,
  CurrencySelect,
  LabelRow,
  Aligner,
  StyledDropDown,
  InputPanel,
  Container,
  StyledTokenName,
  StyledBalanceMax,
  StyledNFTWrap
} from './styleds'

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  onWrap?: () => void
  showMaxButton: boolean
  showWrapButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  customBalanceText?: string
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  showWrapButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  customBalanceText
}: CurrencyInputPanelProps) {
  const { t } = useTranslation()
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [wrapModalOpen, setWrapModalOpen] = useState(false)
  const { account, chainId } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useTheme()
  const currentChainId = chainId ? chainId : ''
  const handleDismissSearchModal = useCallback(() => {
    setSearchModalOpen(false)
  }, [setSearchModalOpen])

  const handleDismissWrapModal = useCallback(() => {
    setWrapModalOpen(false)
  }, [setWrapModalOpen])

  console.log(pair, currency)

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <TYPE.body color={theme.text2} fontWeight={500} fontSize={16}>
                {label}
              </TYPE.body>
              {account && (
                <TYPE.body
                  onClick={onMax}
                  color={theme.text2}
                  fontWeight={500}
                  fontSize={14}
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance &&
                    !!currency &&
                    selectedCurrencyBalance &&
                    account &&
                    showMaxButton &&
                    label !== 'To' && <StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>}
                  {!hideBalance && !!currency && selectedCurrencyBalance
                    ? (customBalanceText ?? 'Balance: ') + selectedCurrencyBalance?.toSignificant(6)
                    : ' -'}
                </TYPE.body>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={val => {
                  onUserInput(val)
                }}
              />
              {account &&
                showWrapButton &&
                label !== 'To' &&
                currency &&
                currency.symbol &&
                erc20ToErc721TokenInfo[currentChainId][currency.symbol] &&
                (erc20ToErc721TokenInfo[currentChainId][currency.symbol].token.symbol === 'CK' ? (
                  <ExternalLink
                    href={
                      getEtherscanLink(
                        0,
                        erc20ToErc721TokenInfo[currentChainId][currency.symbol].token.address,
                        'address'
                      ) + '#writeContract'
                    }
                  >
                    ESCAN
                  </ExternalLink>
                ) : (
                  <StyledNFTWrap
                    onClick={() => {
                      if (!disableCurrencySelect) {
                        setWrapModalOpen(true)
                      }
                    }}
                  >
                    WRAP
                  </StyledNFTWrap>
                ))}
            </>
          )}
          <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setSearchModalOpen(true)
              }
            }}
          >
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
              ) : currency ? (
                <CurrencyLogo currency={currency} size={'24px'} />
              ) : null}
              {pair ? (
                <StyledTokenName className="pair-name-container">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </StyledTokenName>
              ) : (
                <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? currency.symbol.slice(0, 4) +
                      '...' +
                      currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                    : currency?.symbol) || t('selectToken')}
                </StyledTokenName>
              )}
              {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={searchModalOpen}
          onDismiss={handleDismissSearchModal}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
      {!disableCurrencySelect && onCurrencySelect && (
        <NFTWrapModal
          isOpen={wrapModalOpen}
          onDismiss={handleDismissWrapModal}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
