export const getHighestIndexTokenToFetch = (
  tokenBalance: number,
  tokenPageSize: React.MutableRefObject<number>,
  tokenPageOffset: number
): number => {
  if (!tokenBalance) return 0
  else
    return tokenPageSize.current + tokenPageOffset < tokenBalance
      ? tokenPageSize.current + tokenPageOffset
      : tokenBalance
}
