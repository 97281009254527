import React, { MouseEvent } from 'react'
import { Currency } from '@uniswap/sdk'
import styled from 'styled-components'
import { TokenCardImage, TokenCardCurrencyName, TokenCardId } from '../wrapStyleds'

const CardStyle = styled.div<{ selected?: boolean }>`
  box-sizing: border-box;
  width: calc(50% - 15px);
  background: white;
  box-shadow: ${({ theme, selected }) => (selected ? `inset 0px 0px 0px 5px ${theme.primary1}` : 'none')};
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 20px;
  margin-right: 10px;
  cursor: pointer;
`
// border: 1px solid ${({ theme }) => theme.bg2}
interface TokenCardProps {
  tokenId: number | string
  selected: boolean
  onTokenCardClick: (e: MouseEvent<HTMLElement>, id: string) => void
  selectedCurrency?: Currency | null
  imageUri: string
  name: string
}

const TokenCard = ({ tokenId, selected, onTokenCardClick, imageUri, name }: TokenCardProps) => {
  return (
    <CardStyle selected={selected} onClick={e => onTokenCardClick(e, tokenId.toString())}>
      <TokenCardImage src={imageUri} />
      <TokenCardCurrencyName>{name}</TokenCardCurrencyName>
      <TokenCardId>#{tokenId}</TokenCardId>
    </CardStyle>
  )
}
export default TokenCard
