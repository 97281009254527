import styled from 'styled-components'
import Column from '../../Column'
import { RowBetween } from '../../Row'

export const ContentWrapper = styled(Column)`
  width: 100%;
  position: relative;
  padding: 16px;
`

export const TokenSearchGridLayout = styled.div`
  display: grid;
  gap: 16px;
  max-height: 100%;
  min-height: 200px;
`

export const ToggleWrapper = styled(RowBetween)`
  background-color: ${({ theme }) => theme.bg3};
  border-radius: 12px;
  padding: 6px;
`

export const ToggleOption = styled.div<{ active?: boolean }>`
  width: 48%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-weight: 600;
  background-color: ${({ theme, active }) => (active ? theme.bg1 : theme.bg3)};
  color: ${({ theme, active }) => (active ? theme.text1 : theme.text2)};
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

export const CenterDiv = styled.div`
  margin-right: auto;
  margin-left: auto;
`

export const TokenCardHolder = styled.div`
  height: 100%;
  width: 100%;
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1 1 0%;
`

export const WalletTokenCardHolder = styled.div`
  width: 100%;
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 520px;
  ${({ theme }) =>
    theme.mediaWidth.upToMedium({
      maxHeight: '480px'
    })}
  ${({ theme }) =>
    theme.mediaWidth.upToSmall({
      maxHeight: '400px'
    })}
`

export const TokenCardImage = styled.img`
  max-width: 100%;
`

export const TokenCardInfoTextHolder = styled.div`
  width: 100%;
`

export const TokenCardCurrencyName = styled.div`
  width: 100%;
  font-size: 14px;
  color: #6c7284;
`

export const TokenCardId = styled.div`
  width: 100%;
  color: #3d4463;
  font-weight: bold;
`
