import React, { MouseEvent, ReactNode } from 'react'
import { Currency } from '@uniswap/sdk'
import { TokenCardHolder, WalletTokenCardHolder, CenterDiv } from '../wrapStyleds'
import TokenCard from './TokenCard'
import Row from '../../../Row'
import Loader from '../../../Loader'
import { SelectableTokenHolder } from '../types'
import WalletTokenCard from './WalletTokenCard'

function ContainerTokenCard({
  children,
  handleScroll,
  isWalletView
}: {
  children: ReactNode
  handleScroll: (e: any) => void
  isWalletView: boolean
}) {
  if (isWalletView === true) {
    return <WalletTokenCardHolder onScroll={handleScroll}> {children} </WalletTokenCardHolder>
  } else {
    return <TokenCardHolder onScroll={handleScroll}> {children} </TokenCardHolder>
  }
}

interface TokenSearchAndSelectionDisplayProps {
  displayedTokens?: SelectableTokenHolder
  searchQuery: string
  selectedCurrency?: Currency | null
  fetchMoreTokens: () => void
  fetchingTokens: boolean
  onTokenCardClick: (e: MouseEvent<HTMLElement>, id: string) => void
  tokenBalance: number | undefined
  allTokensFetched: boolean
  isWalletView: boolean
}

export function TokenSearchAndSelectionDisplay({
  displayedTokens,
  searchQuery,
  selectedCurrency,
  fetchMoreTokens,
  fetchingTokens,
  onTokenCardClick,
  tokenBalance,
  allTokensFetched,
  isWalletView
}: TokenSearchAndSelectionDisplayProps) {
  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1
    if (bottom && !allTokensFetched) fetchMoreTokens()
  }

  const displayedCards = () => {
    if (!(tokenBalance && tokenBalance > 0)) return null
    const elementsToReturn: JSX.Element[] = []
    if (typeof displayedTokens !== 'undefined') {
      if (isWalletView === true) {
        Object.keys(displayedTokens).forEach((tokenId: string, i: number) => {
          if (tokenId.includes(searchQuery) || displayedTokens[tokenId].isSelected || !searchQuery) {
            elementsToReturn.push(
              <WalletTokenCard
                tokenId={tokenId}
                imageUri={displayedTokens[tokenId].imageUri}
                name={displayedTokens[tokenId].name}
                key={i}
              />
            )
          }
        })
      } else {
        Object.keys(displayedTokens).forEach((tokenId: string, i: number) => {
          if (tokenId.includes(searchQuery) || displayedTokens[tokenId].isSelected || !searchQuery) {
            elementsToReturn.push(
              <TokenCard
                selectedCurrency={selectedCurrency}
                onTokenCardClick={onTokenCardClick}
                tokenId={tokenId}
                selected={displayedTokens[tokenId].isSelected || false}
                imageUri={displayedTokens[tokenId].imageUri}
                name={displayedTokens[tokenId].name}
                key={i}
              />
            )
          }
        })
      }
    }
    return elementsToReturn
  }

  const displayedLoader = () => {
    if (typeof tokenBalance === 'undefined' || Number.isNaN(tokenBalance)) return 'Fetching...'
    if (typeof tokenBalance !== 'undefined' && tokenBalance === 0) return 'No tokens of this type found.'
    else if (fetchingTokens)
      return (
        <Row>
          <CenterDiv>
            <Loader />
          </CenterDiv>
        </Row>
      )
    else return null
  }

  return (
    <ContainerTokenCard handleScroll={handleScroll} isWalletView={isWalletView}>
      {displayedCards()}
      {displayedLoader()}
    </ContainerTokenCard>
  )
}
