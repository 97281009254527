import styled from 'styled-components'
import { AutoColumn } from '../Column'

export const Wrapper = styled.div`
  width: 100%;
  flex: 1 1;
  position: relative;
`

export const PaddedColumn = styled(AutoColumn)`
  padding: 20px;
`

export const BottomGrouping = styled.div`
  margin-top: 0.5rem;
`
