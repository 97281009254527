import { Token, TokenAmount } from '@uniswap/sdk'
import { useMemo } from 'react'

import { useTokenContract, useERC721TokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

export function useTokenAllowance(token?: Token, owner?: string, spender?: string): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result

  return useMemo(() => (token && allowance ? new TokenAmount(token, allowance.toString()) : undefined), [
    token,
    allowance
  ])
}

export function useApprovedForAll(token?: Token, owner?: string, operator?: string): boolean | undefined {
  const contract = useERC721TokenContract(token?.address, false)

  const inputs = useMemo(() => [owner, operator], [owner, operator])
  const allowance = useSingleCallResult(contract, 'isApprovedForAll', inputs).result
  if (allowance === undefined) return undefined
  return allowance[0]
}
