import React from 'react'
import { ButtonPrimary } from '../../../components/Button'
import { AutoRow } from '../../../components/Row'
import Loader from '../../../components/Loader'

interface WrapUnwrapButtonProps {
  width: string
  showWrapMode: boolean
  onWrapClick?: () => void
  onUnwrapClick?: () => void
  pendingTransactions: string[]
  approval: any // not sure
  ApprovalState: any // not sure
  numberOfSelectedTokens: number
  searchQuery: string
}

export const WrapUnwrapButton = ({
  width,
  showWrapMode,
  onWrapClick,
  onUnwrapClick,
  pendingTransactions,
  approval,
  ApprovalState,
  numberOfSelectedTokens,
  searchQuery
}: WrapUnwrapButtonProps) => {
  return (
    <ButtonPrimary
      onClick={showWrapMode ? onWrapClick : onUnwrapClick}
      disabled={
        pendingTransactions.length > 0 ||
        (showWrapMode
          ? approval !== ApprovalState.APPROVED || numberOfSelectedTokens < 1
          : !searchQuery.match(/^[0-9]+$/))
      }
      width={width}
    >
      {pendingTransactions.length > 0 && approval === ApprovalState.APPROVED ? (
        <AutoRow gap="6px" justify="center">
          Pending <Loader stroke="white" />
        </AutoRow>
      ) : showWrapMode ? (
        numberOfSelectedTokens < 1 ? (
          'Select a token'
        ) : (
          `${'Wrap ' + numberOfSelectedTokens + ' tokens'} `
        )
      ) : searchQuery ? (
        `${'Unwrap ' + searchQuery + ' tokens'} `
      ) : (
        'Unwrap 0 tokens'
      )}
    </ButtonPrimary>
  )
}
