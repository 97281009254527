import { SelectableTokenHolder } from './types'

export const updateListOfSelectedTokenIds = (
  displayedTokens: SelectableTokenHolder,
  setSelectedTokenIds: (value: React.SetStateAction<number[]>) => void
) => {
  const newSelectedTokenIds: number[] = []
  Object.keys(displayedTokens).forEach(key => {
    if (displayedTokens[key].isSelected) {
      newSelectedTokenIds.push(parseInt(key))
    }
  })
  setSelectedTokenIds(newSelectedTokenIds)
}
