// used to mark unsupported tokens, these are hosted lists of unsupported tokens
/**
 * @TODO add list from blockchain association
 */
export const UNSUPPORTED_LIST_URLS: string[] = []
const AXIE_LIST = 'https://raw.githubusercontent.com/boxswap/token-lists/main/axie/axieTokenList.json'
const CK_LIST = 'https://raw.githubusercontent.com/boxswap/token-lists/main/cryptokitties/cryptokittiesList.json'
const SAND_LIST = 'https://raw.githubusercontent.com/boxswap/token-lists/main/sand/sandboxList.json'
const GEMINI_LIST = 'https://www.gemini.com/uniswap/manifest.json'

//const COINGECKO_LIST = 'https://tokens.coingecko.com/uniswap/all.json'

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
	CK_LIST,
	SAND_LIST,
	AXIE_LIST,
	GEMINI_LIST,
	...UNSUPPORTED_LIST_URLS // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [AXIE_LIST, SAND_LIST]
