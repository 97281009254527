import React, { useState, useEffect, useRef, MouseEvent } from 'react'
import { SelectableTokenHolder } from '../types'
import { TokenSearchAndSelectionDisplay } from './TokenSearchAndSelectionDisplay'
import { fetchTokensUsingAxie, fetchTokensUsingOpenSea, fetchTokensUsingCryptoKittes } from '../FetcherAPI'

interface WRAPTraditionalAPIProps {
  account: string | null | undefined
  displayedTokens: SelectableTokenHolder
  setDisplayedTokens: any
  selectedCurrencySymbol: string
  isWalletView: boolean
}

const WRAPTraditionalAPI = ({
  account,
  displayedTokens,
  setDisplayedTokens,
  selectedCurrencySymbol,
  isWalletView
}: WRAPTraditionalAPIProps) => {
  const [searchQuery /*, setSearchQuery*/] = useState<string>('')
  const [tokenPageOffset, setTokenPageOffset] = useState<number>(0)
  const tokenPageSize = useRef<number>(12)
  const [fetchingTokens, setFetchingTokens] = useState<boolean>(true)
  const fetchedTokens = useRef<SelectableTokenHolder>({})
  const [allTokensFetched, setAllTokensFetched] = useState<boolean>(false)

  interface FetchTokensProps {
    ownerAddress: string
    offset: number
    limit: number
    selectedCurrencySymbol: string
  }

  const getTokensUsingFetchAPI = async ({ ownerAddress, offset, limit, selectedCurrencySymbol }: FetchTokensProps) => {
    switch (selectedCurrencySymbol) {
      case 'WOA':
      case 'AXIE':
        return await fetchTokensUsingAxie(ownerAddress, offset, selectedCurrencySymbol)
      case 'WSL':
      case 'S-LAND':
        return await fetchTokensUsingOpenSea(ownerAddress, offset, limit)
      case 'WCK':
      case 'CK':
      case 'WG0':
      case 'WVG0':
        return await fetchTokensUsingCryptoKittes(ownerAddress, offset, selectedCurrencySymbol)
      default:
        return {}
    }
  }

  useEffect(() => {
    // clean up controller
    let isSubscribed = true
    ;(async function() {
      // const ownerAddress:string = '0x09191d18729da57a83a9afc8ace0c8d7d104e118'
      const ownerAddress: string = account || ''
      const { tokens, allTokensFetched } = await getTokensUsingFetchAPI({
        ownerAddress: ownerAddress,
        offset: tokenPageOffset,
        limit: tokenPageSize.current,
        selectedCurrencySymbol: selectedCurrencySymbol
      })

      if (isSubscribed) {
        if (allTokensFetched === true) {
          setAllTokensFetched(true)
        }

        if (tokens.length > 0) {
          const newDisplayedTokens = {
            ...fetchedTokens.current,
            ...tokens
          }
          fetchedTokens.current = newDisplayedTokens
          setDisplayedTokens(newDisplayedTokens)
        }
        setFetchingTokens(false)
      }
    })()

    // cancel subscription to useEffect
    return () => {
      isSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, tokenPageOffset])

  const onTokenCardClick = (e: MouseEvent<HTMLElement>, id: string) => {
    if (typeof displayedTokens === 'undefined') return
    const { isSelected } = displayedTokens[id]
    if (isSelected) displayedTokens[id].isSelected = false
    else displayedTokens[id].isSelected = true
    setDisplayedTokens({ ...displayedTokens })
  }
  const fetchMoreTokens = () => {
    if (fetchingTokens === true) return

    setTokenPageOffset(tokenPageOffset + tokenPageSize.current)
    setFetchingTokens(true)
  }

  return (
    <TokenSearchAndSelectionDisplay
      displayedTokens={displayedTokens}
      searchQuery={searchQuery}
      fetchMoreTokens={fetchMoreTokens}
      fetchingTokens={fetchingTokens}
      allTokensFetched={allTokensFetched}
      tokenBalance={1}
      onTokenCardClick={onTokenCardClick}
      isWalletView={isWalletView}
    />
  )
}

export default WRAPTraditionalAPI
