import React, { useMemo, useCallback, useState, Dispatch, SetStateAction } from 'react'
import { Currency, Token } from '@uniswap/sdk'
import { Edit } from 'react-feather'
import { useActiveWeb3React } from 'hooks'
import { useWindowSize } from 'hooks/useWindowSize'
import useTheme from 'hooks/useTheme'
import { erc20ToErc721TokenInfo } from 'constants/erc20ToErc721Token/erc20ToErc721TokenInfo'
import { ERC721Token } from 'state/wallet/hooks'
import { useAllTokens } from 'hooks/Tokens'
import { FixedSizeList } from 'react-window'
import { WalletListBody } from './styleds'
import './walletList.css'
import WalletListHeader from './WalletListHeader'
import CurrencyRow from './CurrencyRow'
import WalletCollectibles from './WalletCollectibles'
import { Wrap as WrapModal } from 'components/SearchModal/Wrap/Wrap'
import Modal from 'components/Modal'
import { TYPE, ButtonText, IconWrapper } from 'theme'
import Row, { RowFixed } from 'components/Row'
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'

export enum Action {
  Wallet = 'WALLET',
  WalletCollectibles = 'WALLET_COLLECTIBLES',
  Wrap = 'WRAP'
}

export default function WalletList() {
  const { chainId, account } = useActiveWeb3React()
  const currencies = useAllTokens()
  const [action, setAction] = useState<Action>(Action.Wallet)
  const [selectedCurrency, setSelectedCurrency] = useState<Token | null>(null)

  const itemData: Currency[] = useMemo(() => {
    if (!chainId) return []

    const currentChainId = chainId ? chainId : ''
    const erc20Toerc721: Currency[] = []

    //we use a map to keep track if we have already added to erc20Toerc721 array
    const erc721Dict: any = {}

    const keysOfObjectFromCurrencies = Object.keys(currencies)

    for (let i = keysOfObjectFromCurrencies.length - 1; i >= 0; i--) {
      const key = keysOfObjectFromCurrencies[i]
      const erc20TokenSymbol = currencies[key]?.symbol || ''
      const tokenInfo = erc20ToErc721TokenInfo[currentChainId][erc20TokenSymbol]
        ? erc20ToErc721TokenInfo[currentChainId][erc20TokenSymbol].token
        : null
      if (tokenInfo && tokenInfo.symbol) {
        if (!erc721Dict[tokenInfo.symbol]) {
          const newTokenInfoForErc721 = {
            ...tokenInfo,
            name: tokenInfo.name || '',
            symbol: tokenInfo.symbol || ''
          }
          erc721Dict[tokenInfo.symbol] = new ERC721Token(newTokenInfoForErc721, 'erc721')
          erc20Toerc721.unshift(erc721Dict[tokenInfo.symbol])
        }
      }
      erc20Toerc721.unshift(currencies[key])
    }

    erc20Toerc721.unshift(Currency.ETHER)

    return erc20Toerc721
  }, [currencies, chainId])

  return (
    <>
      <WalletListHeader action={action} setAction={setAction} />
      <RenderContentByAction
        action={action}
        setAction={setAction}
        currency={selectedCurrency}
        currencies={itemData}
        account={account || ''}
        setSelectedCurrency={setSelectedCurrency}
      />
    </>
  )
}

interface RenderContentByActionProps {
  action: Action
  setAction: Dispatch<SetStateAction<Action>>
  currency: Token | null
  currencies: Currency[]
  account: string
  setSelectedCurrency: Dispatch<SetStateAction<Token | null>>
}

function RenderContentByAction({
  action,
  setAction,
  currency,
  currencies,
  account,
  setSelectedCurrency
}: RenderContentByActionProps) {
  const windowSize = useWindowSize()
  const theme = useTheme()
  const [showManageModal, setShowManageModal] = useState<boolean>(false)

  const height = useMemo(() => {
    if (!windowSize || !windowSize.height) return 0

    if (windowSize && windowSize.width) {
      if (windowSize.width <= 720) {
        // mobile
        return windowSize.height - windowSize.height * 0.39
      } else if (windowSize.width <= 960) {
        // tablet
        return windowSize.height - windowSize.height * 0.35
      } else {
        return windowSize.height - windowSize.height * 0.3
      }
    }

    return 0
  }, [windowSize])

  const RowCurrency = useCallback(
    ({ data, index, style }) => {
      const currency: Currency = data[index]
      return (
        <CurrencyRow
          style={style}
          currency={currency}
          setAction={setAction}
          setSelectedCurrency={setSelectedCurrency}
        />
      )
    },
    [setAction, setSelectedCurrency]
  )

  switch (action) {
    case Action.WalletCollectibles:
      return currency ? (
        <WalletListBody style={{ padding: '0.8rem 1rem' }}>
          <WalletCollectibles account={account} currency={currency} />
        </WalletListBody>
      ) : null
    case Action.Wallet:
    default:
      return (
        <>
          <WalletListBody style={{ padding: '0.8rem 1rem' }}>
            <FixedSizeList
              className="List scrollbars"
              height={height}
              width="100%"
              itemData={currencies}
              itemCount={currencies.length}
              itemSize={56}
            >
              {RowCurrency}
            </FixedSizeList>
            <Row justify="center">
              <ButtonText
                onClick={() => setShowManageModal(true)}
                className="list-token-manage-button"
                style={{ marginTop: '10px' }}
              >
                <RowFixed>
                  <IconWrapper color={theme.primary1} size="16px" marginRight="6px">
                    <Edit />
                  </IconWrapper>
                  <TYPE.main color={theme.primary1}>Manage</TYPE.main>
                </RowFixed>
              </ButtonText>
            </Row>
            {action === Action.Wrap && (
              <Modal isOpen={true} onDismiss={() => setAction(Action.Wallet)} maxHeight={80}>
                <WrapModal isOpen={true} onDismiss={() => setAction(Action.Wallet)} selectedCurrency={currency} />
              </Modal>
            )}
          </WalletListBody>
          <CurrencySearchModal isOpen={showManageModal} onDismiss={() => setShowManageModal(false)} />
        </>
      )
  }
}
