import { Currency } from '@uniswap/sdk'
import { useMemo } from 'react'
import { tryParseAmount } from '../state/swap/hooks'
import { useTransactionAdder } from '../state/transactions/hooks'
//import { useCurrencyBalance } from '../state/wallet/hooks'
import { useTokenContract } from './useContract'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { useActiveWeb3React } from './index'
import { utils } from 'ethers'
// import { Web3Provider } from '@ethersproject/providers'

/**
 * Send erc20 tokens
 * @param Token the selected token
 * @param amount the amount of tokens to be sent
 * @param destAddress the address where tokens are to be sent
 */
//TODO: utilize tryParseAmount
export function useSendERC20Callback(
  token?: Currency,
  amount?: string,
  destAddress?: string
): { execute?: undefined | (() => Promise<void | string>) } {
  const erc20Contract = useTokenContract(token instanceof WrappedTokenInfo ? token.address : undefined)
  const addTransaction = useTransactionAdder()
  const parsedAmount = tryParseAmount(amount, token)

  return useMemo(() => {
    if (!erc20Contract || !amount || !parsedAmount)
      return {
        execute: undefined
      }

    return {
      execute: async () => {
        try {
          const txReceipt = await erc20Contract.transfer(destAddress, String(parsedAmount?.raw))
          //TODO: add symbol to summary message
          addTransaction(txReceipt, { summary: `${amount} ${token?.name} Tokens sent` })
          return txReceipt
        } catch (error) {
          console.error('Could not deposit', error)
          throw error
        }
      }
    }
  }, [erc20Contract, addTransaction, amount, destAddress, parsedAmount, token])
}

/**
 * Send ETH tokens
 * @param amount the amount of tokens to be sent
 * @param destAddress the address where tokens are to be sent
 */
export const useSendETHCallback = (
  amount?: string,
  destAddress?: string
): { execute?: undefined | (() => void); notReadyToExecute?: boolean } => {
  const { library, account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const sendMethod = library && library.provider && library.provider.send ? library.provider.send : undefined

  return useMemo(() => {
    if (!amount || !destAddress || !account || !sendMethod) {
      return {
        execute: undefined,
        readyToExecute: true
      }
    }
    return {
      execute: () => {
        // https://ethereum.stackexchange.com/questions/77028/sending-an-ethereum-transaction-with-ethers-js-and-metamask
        sendMethod(
          {
            method: 'eth_sendTransaction',
            params: [
              {
                from: account,
                to: destAddress,
                value: utils.parseEther(amount)._hex
              }
            ]
          },
          function(err, res) {
            if (err) {
              console.error('Could not deposit', err)
              throw err
            }

            // example output of res
            // id: undefined
            // jsonrpc: "2.0"
            // result: "0x75a46069f5028be365dab89d515a3bd4ff58de2a6293235be0ac7d761ac58cf9"

            const txReceipt = {
              hash: res.result
            }
            addTransaction(txReceipt, { summary: `${amount} ETH Tokens sent` })

            return txReceipt
          }
        )
      }
    }
  }, [amount, destAddress, account, sendMethod, addTransaction])
}
