import React, { CSSProperties, Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { Currency, Token, ETHER } from '@uniswap/sdk'
import { tokenDescriptions, tokenListDescriptions } from 'constants/index'
import { useCurrencyBalance, ERC721Token } from 'state/wallet/hooks'
import { useActiveWeb3React } from 'hooks'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { erc20ToErc721TokenInfo } from 'constants/erc20ToErc721Token/erc20ToErc721TokenInfo'
import { Action } from './index'
import { ItemWrapper, StyledBalanceText } from './styleds'
import Column from '../Column'
import Loader from '../Loader'
import CurrencyLogo from '../CurrencyLogo'
import { Text } from 'rebass'
import { TYPE } from 'theme'
import { ButtonEmpty } from '../Button'
import { ExternalLink } from 'theme/components'
import { getEtherscanLink } from 'utils'
import { QuestionHelperWithChild } from 'components/QuestionHelper'
import { RowFixed } from 'components/Row'
import { YellowCard } from '../Card'
import styled from 'styled-components'

export function currencyKey(currency: Currency): string {
  return currency instanceof Token ? currency.address : currency === ETHER ? 'ETHER' : ''
}

const NetworkCard = styled(YellowCard)`
  padding: 2px;
  padding-bottom: 0px;
  margin: 0px;
  margin-left: 4px;
  margin-bottom: 2px;
  border-radius: 4px;
  font-size: 12px;
  width: initial;
  overflow: hidden;
  flex-shrink: 1;
`

interface CurrencyRowProps {
  currency: Currency
  style: CSSProperties
  setAction: Dispatch<SetStateAction<Action>>
  setSelectedCurrency: Dispatch<SetStateAction<Token | null>>
}

export function getTokenDescription(currency: Currency) {
  if (currency instanceof WrappedTokenInfo) {
    return currency.symbol && tokenDescriptions[currency.symbol] ? tokenDescriptions[currency.symbol] : ''
  } else if (currency instanceof ERC721Token) {
    return currency.name && tokenListDescriptions[currency.name] ? tokenListDescriptions[currency.name] : ''
  }

  return ''
}

export default function CurrencyRow({ currency, style, setAction, setSelectedCurrency }: CurrencyRowProps) {
  const { account, chainId } = useActiveWeb3React()
  const key = currencyKey(currency)
  const balance = useCurrencyBalance(account ?? undefined, currency)

  const erc20Selected = useMemo(() => {
    if (!chainId) return null

    const currentChainId = chainId ? chainId : ''

    if (currency instanceof WrappedTokenInfo && currency.symbol) {
      return erc20ToErc721TokenInfo[currentChainId][currency.symbol]
    }

    return null
  }, [chainId, currency])

  const tokenDescription = useMemo(() => {
    return getTokenDescription(currency)
  }, [currency])

  const handleBtnActionClicked = useCallback(
    (action: Action) => {
      setAction(action)
      if (currency instanceof ERC721Token || (currency instanceof WrappedTokenInfo && erc20Selected))
        setSelectedCurrency(currency)
    },
    [currency, setSelectedCurrency, setAction, erc20Selected]
  )

  return (
    <ItemWrapper style={style} className={`token-item-${key}`}>
      <CurrencyLogo currency={currency} size={'24px'} />
      <Column>
        <RowFixed>
          <Text title={currency.name} fontWeight={500}>
            <QuestionHelperWithChild text={tokenDescription}>{currency.symbol}</QuestionHelperWithChild>
          </Text>
          {currency instanceof ERC721Token ? <NetworkCard title="NFT">NFT</NetworkCard> : ''}
        </RowFixed>
        <TYPE.darkGray ml="0px" fontSize={'12px'} fontWeight={300}>
          {currency.name}
        </TYPE.darkGray>
      </Column>
      <Column>
        {balance ? (
          <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
        ) : (
          <Loader />
        )}
      </Column>
      <Column style={{ justifySelf: 'flex-end' }}>
        {currency instanceof ERC721Token ? (
          <ButtonEmpty onClick={() => handleBtnActionClicked(Action.WalletCollectibles)}>
            <Text title={'VIEW'} fontWeight={500} style={{ fontSize: '12px' }}>
              VIEW
            </Text>
          </ButtonEmpty>
        ) : erc20Selected && erc20Selected.token.symbol === 'CK' ? (
          <ExternalLink
            style={{
              // fontWeight: "normal",
              fontSize: '12px',
              paddingRight: '10px',
              textAlign: 'center'
            }}
            href={getEtherscanLink(0, erc20Selected.token.address, 'address') + '#writeContract'}
          >
            ESCAN
          </ExternalLink>
        ) : (
          erc20Selected && (
            <ButtonEmpty onClick={() => handleBtnActionClicked(Action.Wrap)}>
              <Text title={'WRAP'} fontWeight={500} style={{ fontSize: '12px' }}>
                WRAP
              </Text>
            </ButtonEmpty>
          )
        )}
      </Column>
    </ItemWrapper>
  )
}
