import styled from 'styled-components'
import { Text } from 'rebass'
import { RowBetween } from '../Row'
import { AutoColumn } from '../Column'

export const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`
export const StyledActionText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
  cursor: pointer;
`

export const WalletListBody = styled.div`
  background: ${({ theme }) => theme.bg1};
  padding-bottom: 10px;
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.bg4};
`
//   border-bottom-left-radius: 12px;
//   border-bottom-right-radius: 12px;

export const ItemWrapper = styled(RowBetween)`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 90px);
  grid-gap: 16px;
  :hover {
    background-color: ${({ theme }) => theme.bg3};
  }
  opacity: 1;
  border-bottom: 2px solid ${({ theme }) => theme.bg4};
`

export const PaddedColumn = styled(AutoColumn)`
  padding: 20px;
`
