import React, { useState } from 'react'
import useTheme from 'hooks/useTheme'
import Row, { RowFixed } from 'components/Row'
import { IconWrapper, TYPE } from 'theme'
import { ButtonPrimary } from 'components/Button'

import { Gift } from 'react-feather'
import SendModal from 'components/SendModal/index'

export default function Send() {
  const theme = useTheme()

  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <Row justify="flex-end" padding="0 0 0 0">
        <ButtonPrimary onClick={() => setShowModal(true)} height="8px!important" padding="10px" borderRadius="4px">
          <RowFixed>
            <IconWrapper color={theme.white} size="13px" marginRight="6px" stroke={theme.white}>
              <Gift />
            </IconWrapper>
            <TYPE.main fontSize="13px" color={theme.text1}>
              Send
            </TYPE.main>
          </RowFixed>
        </ButtonPrimary>
      </Row>
      <SendModal isOpen={showModal} onDismiss={() => setShowModal(false)} />
    </>
  )
}
