import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useTheme from 'hooks/useTheme'
import { Pair } from 'data/Pair'
import { Currency } from 'hooks/Tokens'
import { useActiveWeb3React } from 'hooks'
import { useCurrencyBalance } from 'state/wallet/hooks'

import { RowBetween } from 'components/Row'
import { TYPE } from 'theme'
import { Input as NumericalInput } from 'components/NumericalInput'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import CurrencyLogo from 'components/CurrencyLogo'
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'
import {
  InputRow,
  CurrencySelect,
  LabelRow,
  Aligner,
  StyledDropDown,
  InputPanel,
  Container,
  StyledTokenName,
  StyledBalanceMax
} from 'components/CurrencyInputPanel/styleds'

interface CurrencyInputPanelProps {
  value: string
  id: string
  label: string
  pair?: Pair | null
  currency?: Currency | null
  hideCurrencySelect?: boolean
  onCurrencySelect?: (currency: Currency) => void
  disableCurrencySelect?: boolean
  onUserInput: (value: string) => void
  onMax?: () => void
  showCommonBases?: boolean
}

export default function CurrencyInputPanel({
  value,
  id,
  label,
  pair = null,
  currency,
  hideCurrencySelect = false,
  disableCurrencySelect = false,
  onCurrencySelect,
  onUserInput,
  showCommonBases,
  onMax
}: CurrencyInputPanelProps) {
  const { t } = useTranslation()
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)

  const theme = useTheme()
  const handleDismissSearchModal = useCallback(() => {
    setSearchModalOpen(false)
  }, [setSearchModalOpen])

  return (
    <InputPanel id={id}>
      <Container hideInput={false}>
        <LabelRow>
          <RowBetween>
            <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
              {label}
            </TYPE.body>
            {account && (
              <TYPE.body
                color={theme.text2}
                fontWeight={500}
                fontSize={14}
                style={{ display: 'inline', cursor: 'pointer' }}
              >
                {label !== 'To' && !!currency && selectedCurrencyBalance && account && (
                  <StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>
                )}
                {!!currency && selectedCurrencyBalance ? 'Balance: ' + selectedCurrencyBalance?.toSignificant(6) : ' -'}
              </TYPE.body>
            )}
          </RowBetween>
        </LabelRow>
        <InputRow selected={false}>
          <NumericalInput
            className="token-amount-input"
            value={value}
            onUserInput={val => {
              onUserInput(val)
            }}
          />

          {!hideCurrencySelect && (
            <CurrencySelect
              selected={!!currency}
              className="open-currency-select-button"
              onClick={() => {
                if (!disableCurrencySelect) {
                  setSearchModalOpen(true)
                }
              }}
            >
              <Aligner>
                {pair ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                ) : currency ? (
                  <CurrencyLogo currency={currency} size={'24px'} />
                ) : null}

                {pair ? (
                  <StyledTokenName className="pair-name-container">
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </StyledTokenName>
                ) : (
                  <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? currency.symbol.slice(0, 4) +
                        '...' +
                        currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                      : currency?.symbol) || t('selectToken')}
                  </StyledTokenName>
                )}

                <StyledDropDown selected={!!currency} />
              </Aligner>
            </CurrencySelect>
          )}
        </InputRow>
      </Container>

      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={searchModalOpen}
          onDismiss={handleDismissSearchModal}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
