export const fetchMoreTokens = (
  tokenBalance: number | undefined,
  tokenPageSize: React.MutableRefObject<number>,
  tokenPageOffset: number,
  setTokenPageOffset: (value: React.SetStateAction<number>) => void
): void => {
  if (typeof tokenBalance === 'undefined') return
  if (tokenPageOffset + tokenPageSize.current <= tokenBalance)
    setTokenPageOffset(tokenPageOffset + tokenPageSize.current)
}
