import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { Action } from './index'
import { RowBetween } from '../Row'
import Column from '../Column'
import { Text } from 'rebass'
import { RowFixed } from '../Row'
import { ArrowLeft } from 'react-feather'
import { ButtonEmpty } from 'components/Button'
import Send from './Send'

const WrapperHeader = styled(RowBetween)`
  padding: 4px 0 0 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 10px);
  grid-gap: 16px;
  backgroud-color: ${({ theme }) => theme.bg1} !important;
  box-sizing: border-box;
`

export default function WalletListHeader({
  action,
  setAction
}: {
  action: Action
  setAction: Dispatch<SetStateAction<Action>>
}) {
  return (
    <WrapperHeader>
      {action !== Action.WalletCollectibles && (
        <>
          <Column style={{ marginLeft: '20px', opacity: '0.5' }}>
            <Text fontWeight="bold" fontSize="12px">
              ASSET
            </Text>
          </Column>
          <Column style={{ justifySelf: 'flex-end', marginRight: '20px', opacity: '0.5' }}>
            <Text fontWeight="bold" fontSize="12px">
              BALANCE
            </Text>
          </Column>
          <RowFixed style={{ justifySelf: 'flex-end' }} padding="0">
            <Send />
          </RowFixed>
        </>
      )}
      {action === Action.WalletCollectibles && (
        <ButtonEmpty padding="0" onClick={() => setAction(Action.Wallet)}>
          <ArrowLeft size={15} />
          <Text style={{ paddingLeft: '0', fontSize: '15px' }}>Back</Text>
        </ButtonEmpty>
      )}
    </WrapperHeader>
  )
}
