import React, { useState, useCallback, useRef, useMemo, useEffect, MouseEvent } from 'react'
import { Token } from '@uniswap/sdk'
import { SelectableTokenHolder } from '../types'
import { useRecentTransactions } from 'state/transactions/hooks'
import { useMultipleERC721TokensByIndices, useERC721TokenOwner } from '../../../../state/wallet/hooks'
// import { useMultipleERC721TokensByIndices } from '../../../../state/wallet/hooks'
import { TokenSearchAndSelectionDisplay } from '../SearchedAndSelectedTokens/TokenSearchAndSelectionDisplay'
import { getHighestIndexTokenToFetch } from './getHighestIndexTokenToFetch'
import { fetchMoreTokens } from './fetchMoreTokens'
import { pendingTransactionsHaveFinished } from '../pendingTransactionsHaveFinished'

interface WrapBaseProps {
  tokenBalance: number
  account: string | null | undefined
  token: Token
  displayedTokens: SelectableTokenHolder
  setDisplayedTokens: any
  searchQuery: string
  approvalProcessComplete: boolean
  isWalletView: boolean
}

const WrapBase = ({
  tokenBalance,
  account,
  token,
  displayedTokens,
  setDisplayedTokens,
  searchQuery,
  approvalProcessComplete,
  isWalletView
}: WrapBaseProps) => {
  const recentTransactions = useRecentTransactions()
  const [tokenPageOffset, setTokenPageOffset] = useState<number>(0)
  const tokenPageSize = useRef<number>(6)
  const fetchedTokens = useRef<SelectableTokenHolder>({})
  const [pendingStateFromMostRecentRender, setPendingStateFromMostRecentRender] = useState<boolean>(false)
  const [allTokensFetched, setAllTokensFetched] = useState<boolean>(false)
  const loading = useRef<boolean>(false)

  const userIsOwnerOfCurrentInputId = useERC721TokenOwner(token, account ?? undefined, searchQuery)
  // const userIsOwnerOfCurrentInputId = searchQuery === '50' (used for testing on networks where there is no ownerOf contract)

  const reloadTokens = useCallback(() => {
    setTokenPageOffset(0)
    fetchedTokens.current = {}
    setDisplayedTokens({})
    setAllTokensFetched(false)
  }, [setDisplayedTokens])

  useEffect(() => {
    const newPendingState = recentTransactions.pending.length > 0
    if (pendingTransactionsHaveFinished(newPendingState, pendingStateFromMostRecentRender) && approvalProcessComplete)
      reloadTokens()
    setPendingStateFromMostRecentRender(newPendingState)
  }, [recentTransactions.pending.length, pendingStateFromMostRecentRender, reloadTokens, approvalProcessComplete])

  const tokensFromOwnerWallet = useMultipleERC721TokensByIndices(token, account ?? '', {
    from: tokenPageOffset,
    to: getHighestIndexTokenToFetch(tokenBalance, tokenPageSize, tokenPageOffset)
  })

  const memoizedFetchedTokens: SelectableTokenHolder = useMemo(() => {
    const newFetchedTokens: SelectableTokenHolder = { ...fetchedTokens.current }
    const imageUri = (token: string) =>
      `https://storage.googleapis.com/assets.axieinfinity.com/axies/${token}/axie/axie-full-transparent.png`

    tokensFromOwnerWallet.forEach(token => {
      if (token !== 'loading') {
        newFetchedTokens[token] = {
          id: token,
          isSelected: newFetchedTokens[token]?.isSelected || false,
          name: 'Origin Axie',
          imageUri: imageUri(token)
        }
      } else loading.current = true
    })
    if (userIsOwnerOfCurrentInputId)
      newFetchedTokens[searchQuery] = {
        id: searchQuery,
        isSelected: newFetchedTokens[searchQuery]?.isSelected || false,
        name: 'Origin Axie',
        imageUri: imageUri(searchQuery.toString())
      }
    fetchedTokens.current = { ...newFetchedTokens }
    return newFetchedTokens
  }, [tokensFromOwnerWallet, searchQuery, userIsOwnerOfCurrentInputId])

  useEffect(() => {
    if (typeof tokenBalance === 'undefined') return
    if (tokenPageOffset + tokenPageSize.current >= tokenBalance) setAllTokensFetched(true)
  }, [tokenBalance, tokenPageOffset])

  useEffect(() => {
    const newDisplayedTokens = { ...memoizedFetchedTokens }
    if (Object.keys(displayedTokens).length < Object.keys(newDisplayedTokens).length) {
      loading.current = false
      setDisplayedTokens(newDisplayedTokens)
    }
  }, [memoizedFetchedTokens, displayedTokens, setDisplayedTokens])

  const onTokenCardClick = (e: MouseEvent<HTMLElement>, id: string) => {
    if (typeof memoizedFetchedTokens === 'undefined') return
    const { isSelected } = memoizedFetchedTokens[id]
    if (isSelected) memoizedFetchedTokens[id].isSelected = false
    else memoizedFetchedTokens[id].isSelected = true
    setDisplayedTokens({ ...memoizedFetchedTokens })
  }

  return (
    <TokenSearchAndSelectionDisplay
      displayedTokens={displayedTokens}
      searchQuery={searchQuery}
      fetchMoreTokens={() => fetchMoreTokens(tokenBalance, tokenPageSize, tokenPageOffset, setTokenPageOffset)}
      fetchingTokens={loading.current}
      allTokensFetched={allTokensFetched}
      tokenBalance={tokenBalance}
      onTokenCardClick={onTokenCardClick}
      isWalletView={isWalletView}
    />
  )
}

export default WrapBase
