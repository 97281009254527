import React /* useContext, useMemo */ from 'react'
import styled from 'styled-components'
import WalletList from 'components/WalletList'
import { AutoColumn } from 'components/Column'

const WalletContainer = styled(AutoColumn)`
  position: relative;
  max-width: 920px;
  width: 100%;
  ${({ theme }) =>
    theme.mediaWidth.upToMedium({
      width: '100%'
    })}
  ${({ theme }) =>
    theme.mediaWidth.upToSmall({
      width: '100%'
    })}
`

export default function Wallet() {
  return (
    <>
      <WalletContainer>
        <WalletList />
      </WalletContainer>
    </>
  )
}
